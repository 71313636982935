<template>
  <div class="address-view">
    <el-button class="v-primary ml10" type="primary" @click="centerDialogVisible = true">添加企业名片视频</el-button>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="videoTitle" align="center" label="标题" />
        <el-table-column align="center" prop="coverImg" label="封面">
          <template slot-scope="scope">
            <el-image style="width: 60px; height: 60px" :src="scope.row.videoCover" fit="contain"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="sort" align="center" label="排序" />
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, false);
                bEdit = true;
              "
              >编辑</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              k
              cancel-button-text="取消"
              @confirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加视频"
      :visible.sync="centerDialogVisible"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
        <el-form-item label="视频标题" prop="videoTitle">
          <el-input v-model="ruleForm.videoTitle"></el-input>
        </el-form-item>
        <el-form-item label="视频内容" prop="videoUrl">
          <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video v-if="ruleForm.videoUrl" :src="ruleForm.videoUrl" width="100%" height="100%" controls>
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过10M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频封面" prop="videoCover">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="ruleForm.sort" :min="0" :max="999999" label="描述文字"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPageCompanyBusinessCardVideo,
  addCompanyBusinessCardVideo,
  updateCompanyBusinessCardVideo,
  deleteCompanyBusinessCardVideo,
} from "@/api/companyManage.js";
let ac;
export default {
  name: "addressPage",
  components: {
    commonTable,
    customPopconfirm,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      radioStatusArr: [
        { text: "启用", value: true },
        { text: "禁用", value: false },
      ],
      // 上传视频|图片 start
      isUpdate: true,
      fileList: [],
      // 上传视频|图片 end
      jurisdictionA: "",
      jurisdictionB: "",
      // 添加公司名片视频弹框
      bEdit: false,
      centerDialogVisible: false,
      rules: {
        videoTitle: [{ required: true, message: "请输入视频标题", trigger: "blur" }],
        videoCover: [{ required: true, message: "请上传视频封面", trigger: "blur" }],
        videoUrl: [{ required: true, message: "请上传视频", trigger: "blur" }],
      },
      ruleForm: {
        videoId: "",
        videoTitle: "",
        videoCover: "",
        videoUrl: "",
        sort: 0,
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  beforeDestroy() {},
  computed: {
    ...mapState(["routerArr"]),
    uploadDisabled() {
      return this.ruleForm.videoCover !== "";
    },
  },
  watch: {
    routerArr(val) {
      console.log(val);
      this.jurisdictionA = val.includes("080");
      this.jurisdictionB = val.includes("081");
      if (this.jurisdictionA || this.jurisdictionB) {
        this.queryPage();
      }
    },
  },
  methods: {
    // 请求企业地址
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      queryPageCompanyBusinessCardVideo(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || [];
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.$nextTick(() => {
        let data = JSON.parse(JSON.stringify(val));
        this.centerDialogVisible = true;
        this.ruleForm = {
          videoId: data.videoId,
          videoTitle: data.videoTitle,
          videoCover: data.videoCover,
          videoUrl: data.videoUrl,
          sort: data.sort,
        };
        this.fileList = [{ url: data.videoCover, uid: 1 }];
      });
    },
    /**@method 添加公司名片视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              ...this.ruleForm,
            };
            if (this.bEdit) {
              await updateCompanyBusinessCardVideo(data);
            } else {
              await addCompanyBusinessCardVideo(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "添加成功",
              type: "success",
            });
            await this.getTableData();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 名片视频ID
     */
    async del(val) {
      console.log(val);
      try {
        await deleteCompanyBusinessCardVideo({ videoId: val });
        await this.getTableData();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.videoUrl = `https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 30;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 30MB!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videoCover = "";
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.videoCover = `https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/${res.data}`;
    },
    /**@method 模态框打开时的回调 */
    openDialog() {},
    /**@method 模态框关闭的回调 */
    close() {
      this.ruleForm = {
        videoId: "",
        videoTitle: "",
        videoCover: "",
        videoUrl: "",
        sort: 0,
      };
      this.fileList = [];
      this.bEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
